<template>
  <transition>
    <div
      id="popUpVue"
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-40 bg-black-800 bg-opacity-30 px-3"
    >
      <div
        :class="[
          'flex flex-col items-center gap-1 relative rounded-lg bg-white py-10 px-3',
          boxSize
        ]"
      >
        <button
          v-if="showCloseIcon"
          @click="close"
          aria-label="Kapat"
          class="absolute top-4 right-4 hover:opacity-50"
        >
          <svg-icon name="close" class="w-4 h-4" />
        </button>
        <div v-if="type == 'celebration'">
          <img src="@/assets/images/pages/diwali-sparkles-stars.gif" alt="Celebrate Gif" class="h-32">
        </div>
        <svg-icon v-else name="notification-bell" class="" />
        <div class="font-medium text-center">{{ title }}</div>
        <div class="text-sm text-center">{{ message }}</div>
        <div class="flex flex-col gap-4 w-full mt-8">
          <main-button
            @click="accept()"
            :text="buttonText"
            :size="1"
          />
          <main-button
            v-if="showSecondButton"
            @click="acceptSecond()"
            :type="'blue-bordered'"
            :text="secondButtonText"
            :size="1"
          />
          <main-button
            v-if="showClosedButton"
            @click="close()"
            :type="'blue-bordered'"
            :text="closeButtonText"
            :size="1"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mainButton from "@/common/components/buttons/mainButton.vue";

export default {
  props: {
    type: {
      type: String,
      default: "info"
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    template: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 3
    },
    buttonText: {
      type: String,
      default: "Tamam"
    },
    showSecondButton: {
      type: Boolean,
      default: false
    },
    secondButtonText: {
      type: String,
      default: "--"
    },
    showClosedButton: {
      type: Boolean,
      default: true
    },
    closeButtonText: {
      type: String,
      default: "Vazgeç"
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
  },
  components: {
    mainButton
  },
  methods: {
    accept() {
      this.$emit("confirm", true)
    },
    acceptSecond() {
      this.$emit("confirm", 'secondButtonClicked')
    },
    close() {
      this.$emit("confirm", false)
    }
  },
  computed: {
    confirmButtonClassString() {
      let classString = ""
      if (this.type == "error") classString = "bg-error-400"
      else if (this.type == "success") classString = "bg-success-400"
      else if (this.type == "info" || this.type == "dialog")
        classString = "bg-primary-400"
      return classString
    },
    boxSize() {
      let size = "w-64"
      if (this.size == 2) size = "w-96"
      if (this.size > 2) size = "w-full max-w-xs"
      return size
    }
  }
}
</script>

<style></style>
