import axios from "../axios/default";
import authAxios from "../axios/authAxios";
import store from "@/store/index";
import { userService } from "../user/index";
import { getCookie, setCookie, deleteCookie } from "@/common/helpers/user"

export const authenticationService = {
  refreshAccessToken: async () => {
    let accessToken = await axios
      .post("users/insecure/credential/accessToken", getCookie("refreshTokenB"))
      .catch((error) => {
        throw error;
      });
    accessToken = accessToken.data.accessToken;
    return accessToken;
  },
  forgotPassword: async (email) => {
    return await axios.post("insecure/credential/forgotPassword", null, {
      params: { email: email },
    });
  },
  checkVerifyCode: async (email, confirmCode) => {
    return await axios.post("insecure/credential/checkForgotConfirmCode", {
      email: email,
      confirmCode: confirmCode,
    });
  },
  resetPassword: async (email, confirmCode, newPassword, confirmation) => {
    return await axios.post("insecure/credential/resetPasswordForgot", {
      email: email,
      confirmCode: confirmCode,
      newPassword: newPassword,
      confirmation: confirmation,
    });
  },
  // bireysel
  register: async (
    nameSurname,
    email,
    phone,
    couponCode,
    city,
    confirmType
  ) => {
    // kayit ol
    return await axios.post("unAuth/register", {
      nameSurname: nameSurname,
      email: email,
      phone: phone,
      couponCode: couponCode,
      city: city,
      confirmType: confirmType,
    });
  },
  sendEmailOneCode: async (email) => {
    // maile tek kullanimlik kod gonder
    return await axios.post("unAuth/sendEmailOneCode", {
      email: email,
    });
  },
  sendPhoneOneCode: async (phone) => {
    // telefona tek kullanimlik kod gonder
    return await axios.post("unAuth/sendPhoneOneCode", {
      phone: phone,
    });
  },
  logIn: async (params) => {
    let bodyParams = {};
    if (params.type == "emailOrUsernamePassword") {
      // sifre ile giris ise
      bodyParams = {
        email: params.email,
        password: params.password,
        type: params.type,
      };
    } else if (params.type == "emailOneCode") {
      // email ile giris ise
      bodyParams = {
        email: params.email,
        confirmCode: params.confirmCode,
        type: params.type,
      };
    } else {
      // telefon ile giris ise
      bodyParams = {
        phone: params.phone,
        confirmCode: params.confirmCode,
        type: params.type,
      };
    }
    await axios
      .post("insecure/credential/token", bodyParams)
      .then((response) => {
        setCookie("accessTokenB", response.data.accessToken)
        setCookie("refreshTokenB", response.data.refreshToken)
        store.dispatch("user/setIsLoggedIn_Store", true);
      })
      .catch((error) => {
        throw error;
      });
    await userService.pullUserInfo();
    await userService.pullUserPermissions();
    await userService.getBasketList();
    await userService.getUserPackets();
    deleteCookie("basket");
    deleteCookie("basketUserInfos");
  },
  homePageContactForm: async (ad, email, mesaj) => {
    return await axios.post("insecure/credential/sendInfoMail", {
      ad,
      email,
      mesaj,
    });
  },
  logOut: async () => {
    await authAxios.post(
      "insecure/credential/logout",
      getCookie("refreshTokenB")
    );
    deleteCookie("accessTokenB");
    deleteCookie("refreshTokenB");
    store.dispatch("user/resetUser");
    deleteCookie("couponCode");
    deleteCookie("freeCouponCode");
    store.dispatch("user/updateCouponCode", "");
    deleteCookie("basketList");
    deleteCookie("basketUserInfos");
    store.dispatch("user/setIsLoggedIn_Store", false);
  },

  // veli kayit
  parentRegister: async (
    nameSurname,
    phone,
    confirmType,
    requestId,
    password
  ) => {
    // veli kayit ol
    return await axios.post("unAuth/register/studentParent", {
      nameSurname: nameSurname,
      phone: phone,
      confirmType: confirmType,
      requestId: requestId,
      password: password
    });
  },

  forgotPasswordPhone: async (phone) => {
    return await axios.post("insecure/credential/forgotPasswordPhone", null, {
      params: { phone: phone },
    });
  },
  forgotPasswordPhoneCheck: async (phone, confirmCode) => {
    return await axios.post("insecure/credential/checkForgotConfirmCodePhone", {
      phone: phone,
      confirmCode: confirmCode,
    });
  },
  forgotPasswordPhoneReset: async (phone, confirmCode, newPassword, confirmation) => {
    return await axios.post("insecure/credential/resetPasswordForgotPhone", {
      phone: phone,
      confirmCode: confirmCode,
      newPassword: newPassword,
      confirmation: confirmation,
    });
  },
};
